.filedrag {
    border: 2px dashed #AAA;
    border-radius: 7px;
    cursor: default;
    padding: 15px;
    margin-bottom: 20px;
}

.filedrag .drag-label {
    font-weight: bold;
    text-align: center;
    display: block;
    color: #AAA;
    margin-top: 10px;
    margin-bottom: -10px;
}

.filedrag.hover {
    border-color: #F00;
    border-style: solid;
    box-shadow: inset 0 3px 4px #888;
}

.filedrag.hover .drag-label {
    color: #F00;
}

.custom-file-input-wrapper {
    position: relative;
}

.custom-file-input-wrapper .custom-file-input-button {
    position: relative;
    overflow: hidden;
}

.custom-file-input-wrapper .custom-file-input-button * {
    cursor: pointer;
}

.custom-file-input-wrapper .custom-file-input-button input[type="file"] {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
}
